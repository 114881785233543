/* Fittings.css */
.container-wrapper {
    display: flex;
  }
  
  .sidebar-container {
    width: 200px; /* Adjust the width as needed */
  }
  
  .content-container {
    flex-grow: 1;
    padding: 20px; /* Adjust the padding as needed */
  }
  