/* FavoritePage.css */
.body.FavoritePage-body {
    background-color: #d42d2d; /* Set your desired background color */
    margin: 0; /* Remove default body margin */
  }
  .favorite-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
  }
  
  .favorite-card {
    width: 250px;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
  }
  
  .favorite-card:hover {
    transform: scale(1.05);
  }
  
  .favorite-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .favorite-card .card-body {
    padding: 10px;
  }
  
  .favorite-card .btn-danger {
    margin-top: 10px;
    width: 100%;
  }
  .centered-container {
    text-align: center;
  }
  
.favorite-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
  }
  
  .favorite-card {
    width: 250px;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
  }
  
  .favorite-card:hover {
    transform: scale(1.05);
  }
  
  .favorite-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .favorite-card .card-body {
    padding: 10px;
  }
  
  .favorite-card .btn-danger {
    margin-top: 10px;
    width: 100%;
  }
  .centered-container {
    text-align: center;
  }
  